import type { FC } from 'react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';

import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button';
import { N0, N30, N90, N200, N700, B200, B400 } from '@atlaskit/theme/colors';
import MoreIcon from '@atlaskit/icon/glyph/more';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';

import {
	VIEW_REACTIONS_EXPERIENCE,
	DELETE_INLINE_COMMENT_EXPERIENCE,
	EDIT_INLINE_COMMENT_EXPERIENCE,
	EDIT_INLINE_COMMENT_LOAD_EXPERIENCE,
	EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
	RESOLVE_INLINE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
	startEditorExperiences,
} from '@confluence/experience-tracker';
import { DisabledCommentAction } from '@confluence/comment';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import type { ReactionContainerType } from '@confluence/reactions';
import { Reactions, ReactionsCommentsQuery, ReactionLocation } from '@confluence/reactions';
import {
	ReactionsContext,
	useCommentContentContext,
	useCommentContentDispatchContext,
} from '@confluence/comment-context';
import { useMultivariantFeatureFlag } from '@confluence/session-data';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';

import { InlineCommentMode } from './enum/InlineCommentMode';
import { InlineCommentFramework } from './enum/InlineCommentFramework';
import type { CommentPermissions, InlineCommentsMode, CommentAction } from './inlineCommentsTypes';
import { ActionsContainer } from './styled-components';
import { i18n } from './inlineCommentsi18n';

type CommentActionsProps = {
	pageId: string;
	pageType: string;
	commentId: string;
	permissions: CommentPermissions;
	isReply: boolean;
	restrictEdit?: boolean;
	restrictDelete?: boolean;
	deleteComment?: () => void;
	editComment?: () => void;
	resolveComment?: () => void;
	numReplies?: number;
	isCommentActive?: boolean;
	mode: InlineCommentsMode;
	onClose?: () => void;
	overflowMenuPortal?: HTMLDivElement | null;
	supportedActions?: CommentAction[];
};

type DropdownMenuContainerProps = {
	isActive?: boolean;
	isCommentActive?: boolean;
	mode: InlineCommentsMode;
	showClose: boolean;
};

// WS-2470: We need to override this for correct placement of disabled actions in the overflow menu
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OverflowDisabledDropdownItem = styled(DropdownItem)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	padding: '0 !important',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownMenuContainer = styled.span<DropdownMenuContainerProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
    position: absolute;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    top: ${(props: DropdownMenuContainerProps) =>
			props.mode !== 'view' ? (props.showClose ? '-3px' : '-1px') : '12px'};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    right: ${(props: DropdownMenuContainerProps) =>
			props.mode !== 'view' ? (props.showClose ? '22px' : '3px') : '41px'};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    visibility: ${(props: DropdownMenuContainerProps) =>
			!props.isCommentActive && props.mode === 'view-all' ? 'hidden' : 'visible'};

    button, button:hover {
      width: 24px;
      height: 24px;
      border-radius: 4px;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
      ${(props: DropdownMenuContainerProps) =>
				props.isActive
					? `background-color: ${token('color.background.accent.gray.bolder', N700)}`
					: `&:hover { background-color: ${token('color.background.accent.gray.subtler', N30)}; }`}
    };
    & span[role="img"], > svg {
      height: 20px;
      width: 20px;
    }
  `,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ReactionsContainer = styled.div<{ hasReactions: boolean }>((props) =>
	props.hasReactions ? 'flex-basis: 100%;' : '',
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewAllReplyCount = styled.div({
	alignItems: 'flex-end',
	flex: 2,
	textAlign: 'right',
	fontSize: '11px',
	lineHeight: '14px',
	paddingTop: `${token('space.050', '4px')}`,
	cursor: 'pointer',
	color: `${token('color.text.subtlest', N90)}`,
});

type ActionItemProps = {
	mode?: InlineCommentsMode;
	isReply: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ActionItem = styled.li<ActionItemProps>(`
  display: inline-flex;
  flex-flow: row nowrap;
  color: ${token('color.text.subtle', N200)};
  min-height: 24px;
  align-items: center;

  a {
    align-items: flex-end;
    color: ${token('color.text.brand', B400)};
    flex: 2;
    text-align: right;
    font-size: 11px;
    line-height: 14px;
    padding-top: ${token('space.050', '4px')};
    cursor: pointer;
  }

  & button {
    color: ${token('color.text.subtle', N200)};
  }

  & button:focus {
    outline: 2px solid ${token('color.border.focused', B200)};
  }

  &:not(:last-of-type):after {
    content: "•";
    color: ${token('color.text.subtle', N200)};
    padding: 0 ${token('space.050', '4px')} 0 ${token('space.050', '4px')};
  }

  &:first-child:after {
    display: none;
  }
`);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ActionButton = styled.button(`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
  outline: inherit;

  &:focus {
    outline: 2px solid ${token('color.border.focused', B200)};
    outline-offset: 2px;
  }

  :hover span {
    text-decoration: underline;
  }
`);

export const CommentActions: FC<CommentActionsProps> = ({
	pageId,
	pageType,
	commentId,
	permissions,
	isReply,
	restrictEdit,
	restrictDelete,
	deleteComment,
	editComment,
	resolveComment,
	numReplies,
	isCommentActive,
	mode,
	onClose,
	overflowMenuPortal,
	supportedActions = ['edit', 'delete', 'resolve'],
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [useDropdownMenu, setUseDropdownMenu] = useState(false);
	const [isEditable, setIsEditable] = useState(false);
	const [isRemovable, setIsRemovable] = useState(false);
	const [isResolvable, setIsResolvable] = useState(false);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { isReactionsEnabled } = useContext(ReactionsContext);
	const [reactionsCount, setReactionsCount] = useState(0);

	const { hasContentChanged } = useCommentContentContext();
	const { resetContentChanged } = useCommentContentDispatchContext();
	const { showModal } = useDialogs();

	const isLivePage = useIsLivePage();
	const { formatMessage } = useIntl();

	const isRendererAnnotationProviderEnabled =
		useMultivariantFeatureFlag(
			'confluence.frontend.renderer.annotation.provider.inline.comments',
			['annotation-provider', 'not-enrolled', 'query-selectors'],
			'not-enrolled',
			true,
		).cohort === 'annotation-provider';

	const handleGqlError = (error: Error) => {
		experienceTracker.stopOnError({
			name: VIEW_REACTIONS_EXPERIENCE,
			error,
		});
	};

	const { data: reactionsData, loading: reactionsLoading } = useQuery(ReactionsCommentsQuery, {
		variables: { contentId: commentId, containerId: pageId },
		onError: handleGqlError,
		fetchPolicy: 'cache-and-network',
	});

	useEffect(() => {
		const count = reactionsData?.comments?.nodes?.[0]?.reactionsSummary?.reactionsCount || 0;
		setReactionsCount(count);
	}, [reactionsData]);

	useEffect(() => {
		// Actions are decided by the mode we're displaying
		// and the permissions available for the user
		const canEdit = Boolean(
			permissions?.isEditable && editComment && supportedActions.includes('edit'),
		);
		const canDelete = Boolean(
			permissions?.isRemovable && deleteComment && supportedActions.includes('delete'),
		);
		const canResolve = Boolean(
			permissions?.isResolvable && resolveComment && supportedActions.includes('resolve'),
		);

		if (mode === 'view') {
			if (!isReply) {
				// Only parent comments display the dropdown menu
				// when the user has permissions to execute those commands
				if (canEdit || canDelete || canResolve) {
					setUseDropdownMenu(true);
					setIsResolvable(canResolve);
				}
			} else {
				// You cannot resolve replies
				setIsResolvable(false);
			}

			// Users can delete the replies they created
			setIsRemovable(canDelete);
			setIsEditable(canEdit);
		} else {
			setIsRemovable(canDelete);
			setIsEditable(canEdit);
			setIsResolvable(canResolve && !isReply);
		}
	}, [mode, permissions, isReply, supportedActions, deleteComment, editComment, resolveComment]);

	const getInlineCommentModeConstant = () => {
		switch (mode) {
			case 'view':
				return InlineCommentMode.VIEW;
			case 'edit':
				if (isLivePage) {
					return InlineCommentMode.LIVE;
				}
				return InlineCommentMode.EDIT;
		}
	};

	const handleDelete = () => {
		experienceTracker.start({
			name: DELETE_INLINE_COMMENT_EXPERIENCE,
			attributes: {
				mode: getInlineCommentModeConstant(),
				framework:
					mode === InlineCommentMode.EDIT || isRendererAnnotationProviderEnabled
						? InlineCommentFramework.ANNOTATION_PROVIDER
						: InlineCommentFramework.REACT,
				isReply,
			},
		});

		showModal(CommentWarningDialog, {
			onConfirm: () => {
				deleteComment && deleteComment();
			},
			isDelete: true,
			onCancel: () => {
				// abort delete experience if dialog is closed
				experienceTracker.abort({
					name: DELETE_INLINE_COMMENT_EXPERIENCE,
					reason: 'delete dialog closed',
				});
			},
		});
	};

	const handleEditComment = () => {
		startEditorExperiences(
			/* compoundExperience */ {
				name: EDIT_INLINE_COMMENT_EXPERIENCE,
				attributes: {
					mode: getInlineCommentModeConstant(),
					framework:
						mode === InlineCommentMode.EDIT || isRendererAnnotationProviderEnabled
							? InlineCommentFramework.ANNOTATION_PROVIDER
							: InlineCommentFramework.REACT,
					isReply,
					type: isReply ? 'reply' : 'topLevel',
				},
			},
			/* editorLoadExperience */ {
				name: EDIT_INLINE_COMMENT_LOAD_EXPERIENCE,
			},
			/* editorPublishExperience */ {
				name: EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
			},
		);
		editComment && editComment();
	};

	const handleResolveComment = () => {
		const doResolve = () => {
			experienceTracker.start({
				name: RESOLVE_INLINE_COMMENT_EXPERIENCE,
				id: commentId,
				attributes: {
					mode: getInlineCommentModeConstant(),
					framework:
						mode === InlineCommentMode.EDIT || isRendererAnnotationProviderEnabled
							? InlineCommentFramework.ANNOTATION_PROVIDER
							: InlineCommentFramework.REACT,
				},
			});
			resolveComment && resolveComment();
		};

		if (hasContentChanged) {
			showModal(CommentWarningDialog, {
				onConfirm: () => {
					resetContentChanged();
					doResolve();
				},
			});
		} else {
			doResolve();
		}
	};

	const renderEditAction = (isRestricted?: boolean, isDropdownMenu?: boolean) => {
		if (isDropdownMenu) {
			return isRestricted ? (
				<OverflowDisabledDropdownItem testId="disabled-edit-comment-dropdown-action">
					<DisabledCommentAction action="edit" isReply={isReply} isInOverflowMenu />
				</OverflowDisabledDropdownItem>
			) : (
				<DropdownItem
					testId="edit-comment-dropdown-action"
					data-cy="edit-comment-action"
					onClick={handleEditComment}
				>
					<FormattedMessage {...i18n.edit} />
				</DropdownItem>
			);
		}

		return isRestricted ? (
			<DisabledCommentAction action="edit" isReply={isReply} isInOverflowMenu />
		) : (
			<ActionButton
				data-testid="edit-comment-action"
				data-cy="edit-comment-action"
				onClick={handleEditComment}
			>
				<FormattedMessage {...i18n.edit} />
			</ActionButton>
		);
	};

	const renderDeleteAction = (isRestricted?: boolean, isDropdownMenu?: boolean) => {
		if (isDropdownMenu) {
			return isRestricted ? (
				<OverflowDisabledDropdownItem testId="disabled-delete-comment-dropdown-action">
					<DisabledCommentAction action="delete" isReply={isReply} isInOverflowMenu />
				</OverflowDisabledDropdownItem>
			) : (
				<DropdownItem testId="delete-comment-dropdown-action" onClick={handleDelete}>
					<FormattedMessage {...i18n.delete} />
				</DropdownItem>
			);
		}

		return isRestricted ? (
			<DisabledCommentAction action="delete" isReply={isReply} />
		) : (
			<ActionButton data-testid="delete-comment-action" onClick={handleDelete}>
				<FormattedMessage {...i18n.delete} />
			</ActionButton>
		);
	};

	const renderDropdownMenu = (editRestricted?: boolean, deleteRestricted?: boolean) => {
		const dropdownMenu = (
			<DropdownMenuContainer
				data-cy="comment-overflow-actions-menu"
				data-testid="comment-overflow-actions-menu"
				isActive={isDropdownOpen}
				isCommentActive={isCommentActive}
				mode={mode}
				showClose={Boolean(onClose)}
			>
				<DropdownMenu
					testId="comment-overflow-menu"
					isOpen={isDropdownOpen}
					placement="bottom-end"
					onOpenChange={({ isOpen }) => {
						setIsDropdownOpen(isOpen);
					}}
					trigger={({ triggerRef, ...props }) => (
						<Button
							{...props}
							aria-label={formatMessage(i18n.commentActionsMenuLabel)}
							ref={triggerRef}
							appearance="subtle"
							iconBefore={
								<MoreIcon
									label="overflow-menu-icon"
									primaryColor={
										isDropdownOpen
											? token('color.icon.inverse', N0)
											: token('color.icon.subtle', N200)
									}
								/>
							}
						/>
					)}
				>
					<DropdownItemGroup>
						{isResolvable && mode !== 'view-all' && (
							<DropdownItem onClick={handleResolveComment}>
								<FormattedMessage {...i18n.resolve} />
							</DropdownItem>
						)}
						{isEditable && renderEditAction(editRestricted, useDropdownMenu)}
						{isRemovable && renderDeleteAction(deleteRestricted, useDropdownMenu)}
					</DropdownItemGroup>
				</DropdownMenu>
			</DropdownMenuContainer>
		);

		return overflowMenuPortal ? createPortal(dropdownMenu, overflowMenuPortal) : dropdownMenu;
	};

	const renderRepliesLink = () => {
		if (mode === 'view-all' && !isCommentActive)
			return numReplies && numReplies > 0 ? (
				<ViewAllReplyCount data-testid="view-all-inline-replies-link">
					{numReplies > 1 ? `${numReplies} Replies` : `${numReplies} Reply`}
				</ViewAllReplyCount>
			) : null;
	};

	return (
		<Fragment>
			<ActionsContainer>
				<ActionItem mode={mode} isReply={isReply}>
					{renderRepliesLink()}
				</ActionItem>
				{!isReply && isResolvable && mode !== 'view-all' && (
					<ActionItem mode={mode} isReply={isReply}>
						<ActionButton
							data-testid="resolve-comment-action"
							data-cy="resolve-comment-action"
							onClick={handleResolveComment}
						>
							<FormattedMessage {...i18n.resolve} />
						</ActionButton>
					</ActionItem>
				)}
				{!useDropdownMenu && isEditable && (
					<ActionItem mode={mode} isReply={isReply}>
						{renderEditAction(restrictEdit, useDropdownMenu)}
					</ActionItem>
				)}
				{!useDropdownMenu && isRemovable && (
					<ActionItem mode={mode} isReply={isReply}>
						{renderDeleteAction(restrictDelete, useDropdownMenu)}
					</ActionItem>
				)}
				{isReactionsEnabled && !reactionsLoading && (
					<ReactionsContainer hasReactions={reactionsCount > 0}>
						<Reactions
							contentId={commentId}
							containerId={pageId}
							containerType={pageType as ReactionContainerType}
							location={
								mode === 'edit'
									? ReactionLocation.EDITOR_INLINE_COMMENT
									: ReactionLocation.INLINE_COMMENT
							}
							hidePlaceholder={reactionsCount === 0}
						/>
					</ReactionsContainer>
				)}
			</ActionsContainer>
			{useDropdownMenu && renderDropdownMenu(restrictEdit, restrictDelete)}
		</Fragment>
	);
};
